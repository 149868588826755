import React from "react";

const LineShipment = ({shipmentLine, highlight}) => {

    const product = shipmentLine["M_Product_ID"];
    const charge = shipmentLine["C_Charge_ID"];
    const uom = shipmentLine["C_UOM_ID"];

    const getDescription = () => {
        if(product) {
            return product.Name;
        } else if(charge) {
            return charge.Name;
        } else {
            return shipmentLine.Description;
        }
    }

    const getQuantity = () => {
        const entered = shipmentLine["QtyEntered"];
        if(uom && uom.Name) {
            return entered + " " + uom.Name;
        }
        return entered;
    }

    const lineNo = shipmentLine.Line;
    const description = getDescription();
    const quantity = getQuantity();
    const hc = highlight ? " line-highlight" : "";

    return (
        <>
            <p className={"acct-line-h1" + hc}>{lineNo}</p>
            <p className={"acct-line-h1" + hc} style={{gridColumn: "span 2"}}>{description}</p>
            <p className={"acct-line-h1 num" + hc}>{quantity}</p>
        </>
    )
}

export default LineShipment;


const DeliveryIcon = () => {

    return (
        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="39" cy="39" r="39" fill="#F0F0F0"/>
            <circle cx="38.5" cy="38.5" r="24.5" fill="#AFAEFF" stroke="#968EFF" strokeWidth="2"/>
            <path d="M39 52.5L26 45V31L39 24L52 31.5V45L39 52.5Z" fill="#EFC578"/>
            <path
                d="M32.5 27.2285L27.0518 30.1621C26.404 30.511 26 31.1873 26 31.9231V43.6109C26 44.3054 26.3603 44.9502 26.9518 45.3142L37.9518 51.75C38.5946 52.1456 39.4054 52.1456 40.0482 51.75L51.0482 45.3142C51.6397 44.9502 52 44.3054 52 43.6109V31.9231C52 31.1873 51.596 30.511 50.9482 30.1621L39.9482 24.2391C39.3562 23.9203 38.6438 23.9203 38.0518 24.2391L32.5 27.2285ZM32.5 27.2285L45.5 34.2285M39 52V37.7285M39 37.7285L26 30.7285M39 37.7285L45.5 34.2285M45.5 34.2285L52 30.7285"
                stroke="black" strokeWidth="3"/>
            <circle cx="51.5" cy="43.5" r="7.5" fill="white"/>
            <path d="M47 44L48.25 45.25L48.823 45.823C49.2015 46.2015 49.8111 46.2148 50.2058 45.853L55.5 41"
                  stroke="#70AF76" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )

}

export default DeliveryIcon;

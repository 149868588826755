import '../../style/product/product.css'
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

const VendorProductTile = ({ product, altImg, hideMore, ...props }) => {

    const navigate = useNavigate();
    const [imgLoading, setImgLoading] = useState(true);
    const stopImgLoading = () => {
        setImgLoading(false);
    }

    const onMore = () => {
        navigate("/vendor/manageStock/reviewYourProducts/product/" + product.id);
    }

    return (
        <div className="prod-tile" {...props} >
            <div className="prod-tag" />
            <div className='prod-tile-main-content'>
                <div className={`prod-img-container ${imgLoading ? 'loading' : ''}`}>
                    <img
                        src={altImg ? altImg : product.imageUrl}
                        alt=""
                        onLoad={stopImgLoading}
                        onError={stopImgLoading}
                    />
                </div>
                <div className="prod-purchase-info">
                    <p className="prod-code">{product.vendorCode || product.value}</p>
                    <p className='prod-name'>{product.name}</p>
                </div>
                {!hideMore &&
                    <div style={{marginTop: 10}} className='prod-more-details' onClick={onMore}>
                        More Details/ Edit
                    </div>
                }
            </div>
        </div>
    )

}

export default VendorProductTile;

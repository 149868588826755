import HeaderButton from "./HeaderButton";
import {useNavigate} from "react-router-dom";
import useSession from "../../../newStructure/services/state/session/useSession";


const AccountButton = () => {

    const navigate = useNavigate();
    const session = useSession();
    const loggedIn = session.isSignedIn;
    const isVendor = session.isVendor;

    const handleClick = () => {
        if(loggedIn) {
            if(isVendor) {
                navigate("/vendor/accountInformation");
            } else {
                navigate("/account/settings");
            }
        } else {
            navigate("/signin")
        }
    }

    const getTrimmedName = (name) => {
        if(name.length > 30) {
            return name.slice(0,27) + "...";
        } else {
            return name;
        }
    }

    const heading = loggedIn ? getTrimmedName(session.getBusinessName()) : "Account";
    const subHeading = loggedIn ? getTrimmedName(session.getUserName()) : "Sign In"

    return (
        <>
            <HeaderButton
                heading={heading}
                subHeading={subHeading}
                onClick={handleClick}
                className="account"
            >
                <img alt="" src="/assets/Account.svg"/>
            </HeaderButton>
        </>
    )

}

export default AccountButton;

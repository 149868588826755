import {createSlice} from "@reduxjs/toolkit";

const initialState = {

    toReport: {}

}

export const errorReportSlice = createSlice({
    name: 'errorReport',
    initialState,
    reducers: {
        reportError(state, { payload }) {
            state.toReport[payload.code] = payload.error;
        },
        clearErrorReport(state, { payload }) {
            console.log("clearning", state)
            delete state.toReport[payload.code];
        }
    }
});

export const { reportError, clearErrorReport } = errorReportSlice.actions;
export default errorReportSlice.reducer;

import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Divider from "../header/header/Divider";

const NavDrawerAccount = () => {

    const navigate = useNavigate();

    const { session } = useSelector(
        state => state.local.auth
    );

    const navigateToAccount = () => {
        navigate("/account")
    }

    const loggedIn = session?.bPartnerID;

    const title = loggedIn ? session.userName : "Sign In";

    const onAccountClick = () => {
        if(loggedIn) {
            navigateToAccount();
        } else {
            navigate("/signin");
        }
    }
    return (
        <>
            <div className="nav-drawer-account" onClick={onAccountClick}>
                <img alt="" src="/assets/Account.svg"/>
                <p>{title}</p>
            </div>
            <Divider />
        </>
    )
}

export default NavDrawerAccount;

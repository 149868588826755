import {request} from "../../../../res/rest/restRequest";
import {env} from "../../../../res/config/env";
import {api} from "../../../../res/rest/api";
import axios from "axios";
import useSession from "../session/useSession";
import useRequestTracker from "../request/useRequestTracker";
import {useState} from "react";

const downloadIt = (name, blob) => {
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    a.remove();
}

const printPreview = (blob) => {
    const blobURL = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe')
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = function() {
        setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
        }, 1);
    };
};

const useAttachment = ( model, id, attachment ) => {

    const session = useSession();

    const {
        loading,
        sendIt,
    } = useRequestTracker();

    const [ data, setData ] = useState();

    const fetchData = (afterFetch) => {
        const url = request.model(model)
            .id(id)
            .attachment()
            .fileName(attachment.name)
            .buildRequest(env.API_URL);

        const config = {
            headers: {
                Accept: "application/octet-stream",
                Authorization: api.auth(session.sessionData).headers.Authorization
            },
            responseType: 'arraybuffer'
        }

        const req = async () => {
            const response = await axios.get(url, config);
            const blob = new Blob([response.data], { type: attachment.contentType });
            afterFetch(blob);
        }

        sendIt(req);
    }

    const load = () => {
        fetchData(blob => setData(blob));
    }

    const download = () => {
        fetchData(blob => downloadIt(attachment.name, blob));
    }

    return {
        loading,
        data,
        download,
        load,
        printPreview
    }

}

export default useAttachment;

import AccountLayout from "../structure/AccountLayout";
import AccountNav from "../../../../logic/navigation/accountNav";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import useOrders from "../../../../services/state/account/useOrders";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import HeaderOrder from "../../order/headers/HeaderOrder";
import {useDispatch} from "react-redux";
import {useState} from "react";
import Popup from "../../../../../components/general/popup/Popup";
import Dialog from "../../../general/dialog/Dialog";
import DialogTitle from "../../../general/dialog/DialogTitle";
import DialogMessage from "../../../general/dialog/DialogMessage";
import DialogButtonContainer from "../../../general/dialog/DialogButtonContainer";
import DialogButton, {DIALOG_BUTTON_TYPE} from "../../../general/dialog/DialogButton";
import {useNavigate} from "react-router-dom";
import {amendCart} from "../../../../../redux/action/cartActions";
import useSession from "../../../../services/state/session/useSession";
import GeneralMessageDialog from "../../../general/dialog/GeneralMessageDialog";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.orders, {...AccountNav.items.openOrder, selected: true}];

const AmendingDialog = ({ order, onBack, afterFailure }) => {

    const session  = useSession();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const message = "Amending your order will replace your current cart with this order. " +
        "You must go through checkout to save your changes. " +
        "Credit card customers will be charged for any extras, or refunded for reductions. " +
        "If you don’t complete checkout before the delivery cutoff, you’ll lose your delivery date."

    const afterAmend = (success) => {
        if(success) {
            navigate('/checkout/cart');
        } else {
            afterFailure();
        }
    }

    const onAmend = () => {
        dispatch(amendCart({ session: session.sessionData, orderID: order.id, afterAmend }))
    }

    return (
        <Popup>
            <Dialog>
                <DialogTitle>Amend Order {order.DocumentNo}</DialogTitle>
                <DialogMessage>{message}</DialogMessage>
                <DialogButtonContainer>
                    <DialogButton type={DIALOG_BUTTON_TYPE.neutral} onClick={onBack}>
                        Cancel
                    </DialogButton>
                    <DialogButton type={DIALOG_BUTTON_TYPE.positive} onClick={onAmend}>
                        Amend Order
                    </DialogButton>
                </DialogButtonContainer>
            </Dialog>
        </Popup>
    )

}

const OpenOrderContent = () => {

    const {
        loading,
        getOrders,
        orders
    } = useOrders();

    const [ amending, setAmending ] = useState();
    const [ failed, setFailed ] = useState(false);

    useValueChangeListener(() => {
        getOrders({open:true, getShipment:true, pageSize:100});
    }, [], [false]);

    const onAmend = (order) => {
        setAmending(order);
    }

    const canAmend = (order) => {
        return !(order.m_inout?.length > 0);
    }

    return (
        <div className={"acct-content max-width-set"}>
            <LoadingSwitcher loading={loading}>
                {!orders || orders.length === 0 ?
                    <>
                        <p className="acct-h1">No orders</p>
                        <p className="acct-h4">You currently do not have any open orders</p>
                    </> :
                    <>
                        {orders.map(o => <HeaderOrder order={o} showAmend onAmend={onAmend} canAmend={canAmend(o)} />)}
                    </>
                }
                {amending &&
                    <AmendingDialog
                        order={amending}
                        onBack={() => setAmending(null)}
                        afterFailure={() => {setAmending(null); setFailed(true)}}
                    />
                }
                {failed &&
                    <GeneralMessageDialog
                        title="Something went wrong"
                        message="something has gone wrong, you are currently not able to make amendments to that order."
                        buttonText="OK"
                        onDismiss={() => setFailed(false)}
                    />
                }
            </LoadingSwitcher>
        </div>
    )


}

const OpenOrderLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={acctBreadcrumbTrail}>
            <AccountTertiaryNav navList={acctBreadcrumbTrail[1].children} />
            <OpenOrderContent />
        </AccountLayout>
    )
}

export default OpenOrderLayout;

import PageLayout from "../../../../../components/page/PageLayout";
import OpenOrderLayout from "../../../account/layout/orders/OpenOrderLayout";

const PageOpenOrder = () => {

    return (
        <PageLayout requireSignIn={true}>
            <OpenOrderLayout />
        </PageLayout>
    )
}

export default PageOpenOrder;

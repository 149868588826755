import useAuthRequest from "../request/useAuthRequest";
import useValueChangeListener from "../general/useValueChangeListener";
import {request} from "../../../../res/rest/restRequest";
import {env} from "../../../../res/config/env";
import axios from "axios";
import {api} from "../../../../res/rest/api";

const useAttachments = (model, id) => {

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    useValueChangeListener(() => {
        if(id > 0) {
            const req = request.model(model)
                .id(id)
                .attachment();

            const url = req.buildRequest(env.API_URL);
            const fetch = async (session) => {
                const res = await axios.get(url, api.auth(session));
                return res.data.attachments;
            }

            sendIt(fetch)
        }
    }, [id], []);

    return {
        loading,
        error,
        attachments: result
    }

}

export default useAttachments;

import useOutForDelivery from "../../../services/state/account/useOutForDelivery";
import HeaderPlaceHolder from "./HeaderPlaceHolder";
import ContentTile from "../content/ContentTile";
import Delivery from "../../../logic/delivery/delivery";
import ContentContainer from "../content/ContentContainer";
import DeliveryIcon from "../../general/icon/DeliveryIcon";
import DeliveryTrackerMap from "../../delivery/DeliveryTrackerMap";

const PartnerDeliverySwitcher = () => {

    const {
        loading,
        activeDelivery
    } = useOutForDelivery();

    const showMap = activeDelivery?.driver_long && activeDelivery?.driver_lat

    if(loading) {
        return <HeaderPlaceHolder/>
    }

    return (
        <>
            {showMap && <DeliveryTrackerMap delivery={activeDelivery} />
            }
            {activeDelivery &&
                <ContentContainer>
                    <p className="home-content-header">Your next delivery</p>
                    <ContentTile
                        title={Delivery.getUserQuickStatusMessage(activeDelivery)}
                        text={Delivery.getUserStatusMessage(activeDelivery)}
                        navText={"View this order"}
                        link={"account/orders/shipment/" + activeDelivery.DocumentNo}
                        icon={<DeliveryIcon />}
                    />
                </ContentContainer>
            }
        </>
    )
}

export default PartnerDeliverySwitcher;

import Form from "../../form/Form";
import FormHeading from "../../form/FormHeading";
import FormItem from "../../form/FormItem";
import FormItemCustom from "../../form/FormItemCustom";
import FormSelector from "../../form/FormSelector";
import BannerSVGDesktop from "./BannerSVGDesktop";
import '../../style/vendor/bannerForm.css'
import {useMemo, useRef, useState} from "react";
import FormFileUpload from "../../form/FormFileUpload";
import FormButton from "../../form/FormButton";
import BannerSVGMobile from "./BannerSVGMobile";
import {useSelector} from "react-redux";
import useVendorProducts from "../../../services/state/vendor/useVendorProducts";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import Selector from "../../general/input/Selector";
import BannerValidation from "../../../logic/vendor/bannerValidation";
import Popup from "../../../../components/general/popup/Popup";
import DialogOld from "../../general/dialog/DialogOld";
import FormTextArea from "../../form/FormTextArea";
import useAuthRequest from "../../../services/state/request/useAuthRequest";
import bannerFormRequest from "../../../services/rest/request/vendor/bannerFormRequest";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import Calendar from "../../general/calendar/Calendar";
import LoadingSwitcher from "../../general/loading/LoadingSwitcher";
import {condition, request} from "../../../../res/rest/restRequest";
import {env} from "../../../../res/config/env";
import axios from "axios";
import {api} from "../../../../res/rest/api";

const CLICK_ACTION_PRODUCT = "Go to product page";
const CLICK_ACTION_RANGE  = "Show a range of products";
const onClickActions = [null, CLICK_ACTION_PRODUCT, CLICK_ACTION_RANGE];

const SectionSeparator = () => <FormItem style={{height: 30, gridColumn: "span 2"}}></FormItem>

const BannerSelector = ({ defaultSelector, onSelection }) => {

    const [ selected, setSelected ] = useState(defaultSelector);

    const getOptionClassName = (key) => {
        return "banner-option" + (key === selected ? " selected" : "");
    }

    const handleSelection = (key) => () => {
        if(onSelection) onSelection(key);
        setSelected(key);
    }

    return (
        <div className="banner-selector-container">
            <div className={getOptionClassName("L")} onClick={handleSelection("L")}>
                <BannerSVGDesktop isLeaderBoard />
                <p>Leaderboard</p>
            </div>
            <div className={getOptionClassName("S")} onClick={handleSelection("S")}>
                <BannerSVGDesktop isSkyscraper />
                <p>Skyscraper</p>
            </div>
            <div className={getOptionClassName("B")} onClick={handleSelection("B")}>
                <BannerSVGDesktop isSkyscraper isLeaderBoard />
                <p>Both</p>
            </div>
        </div>
    )
}

const CategorySelector = ({ onChange }) => {

    const { categoryMap } = useSelector(
        state => state.session.category
    );
    const categories = useMemo(() => {
        const cats = categoryMap ? Object.values(categoryMap) : [];
        cats.sort((a,b) => a.name.localeCompare(b.name));
        return [null, ...cats];
    }, [categoryMap]);


    return (
        <FormSelector
            name="Category"
            description="Your banner will be displayed on the selected catagory, and all its sub-categories."
            options={categories}
            gridColumn="span 2"
            optionToString={c => c?.name}
            onSelected={c => {
                onChange(c?.id);
            }}
        />
    )
}

const DateSelector = ({ categoryID, onChange }) => {

    const {
        loading,
        result,
        sendIt,
    } = useAuthRequest();

    const [[ startDate, endDate ], setDates ] = useState([]);

    useValueChangeListener(() => {
        if(categoryID > 0) {
            setDates([]);
            const req = request.model("x_Upsell_Product")
                .select("StartDate", "EndDate")
                .filter(condition.eq("M_Product_Category_ID", categoryID))
                .filter(condition.eq("isActive", true))
                .filter(condition.greaterThan("EndDate", "'" + idempiereDate.getDateString(new Date())) + "'");

            sendIt(async (session) => {
                const url = req.buildRequest(env.API_URL);
                const resp = await axios.get(url, api.auth(session));
                return resp.data.records
                    .filter(r => Boolean(r.StartDate) && Boolean(r.EndDate))
                    .map(r => [idempiereDate.getDate(r.StartDate), idempiereDate.getDate(r.EndDate)]);
            });
        }
    }, [categoryID], []);

    const onDateChange = (date, { interrupted, blocked, isInactive }) => {
        if(blocked || isInactive) return;
        if(!endDate && date > startDate && !interrupted) {
            setDates([startDate, date]);
            onChange({ startDate, endDate : date });
        } else {
            onChange({ startDate : date });
            setDates([date]);
        }
    };

    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 3);

    return (
        <FormItemCustom
            gridColumn="span 2"
            name="Display Duration"
            description="Select the date range for your banner to be shown on the site."
            onChange={onChange}
        >
            <div style={{marginTop: 10}}>
                <LoadingSwitcher loading={loading}>
                    <Calendar
                        minDate={minDate}
                        maxDate={maxDate}
                        startDate={startDate}
                        endDate={endDate}
                        onDateClick={onDateChange}
                        blockedOut={result}
                    />
                </LoadingSwitcher>
            </div>
        </FormItemCustom>
    )
}

const BannerImageSetUp = ({ onChange }) => {

    const [ warning, setWarning ] = useState({});
    const [ config, setConfig ] = useState({});

    const imgRequirement = {
        leaderboardImage: { height: 90, width: 1280 },
        mobileLeaderboardImage: { height: 60, width: 320 },
        skyscraperImage: { height: 600, width: 240 }
    }

    const updateConfig = (key, val) => {
        const updatedConfig = {...config, [key]: val};
        setConfig(updatedConfig);
        onChange(updatedConfig);
    }

    const onImageUpload = (key, val) => {
        const { height, width } = imgRequirement[key];
        if(val.height === height && val.width === width) {
            setWarning({...warning, [key]: null});
            updateConfig(key, val);
        } else {
            const message = `Required image size ${width}x${height}, Image Uploaded is ${val.width}x${val.height}`
            setWarning({...warning, [key]: message});
            updateConfig(key, null);
        }
    }

    return (
        <>
            <FormItemCustom gridColumn="span 2">
                <p className="input-form-sub-heading">Banner Type</p>
                <p className="input-form-text">Please select your banner type</p>
                <BannerSelector defaultSelector={config.bannerType} onSelection={t => updateConfig("bannerType", t)}/>
            </FormItemCustom>
            {(config.bannerType === "B" || config.bannerType === "L") &&
                <>
                    <FormFileUpload
                        name={`Leaderboard Image (req. ${imgRequirement.leaderboardImage.width}x${imgRequirement.leaderboardImage.height})`}
                        onFileUploaded={f => onImageUpload("leaderboardImage", f)}
                        gridColumn="span 2"
                        accept="image/*"
                        warning={warning.leaderboardImage}
                    />
                    <FormFileUpload
                        name={`Mobile Leaderboard Image (req. ${imgRequirement.mobileLeaderboardImage.width}x${imgRequirement.mobileLeaderboardImage.height})`}
                        onFileUploaded={f => onImageUpload("mobileLeaderboardImage", f)}
                        gridColumn="span 2"
                        accept="image/*"
                        warning={warning.mobileLeaderboardImage}
                    />
                </>
            }
            {(config.bannerType === "B" || config.bannerType === "S") &&
                <FormFileUpload
                    name={`Skyscraper Image (req. ${imgRequirement.skyscraperImage.width}x${imgRequirement.skyscraperImage.height})`}
                    gridColumn="span 2"
                    onFileUploaded={f => onImageUpload("skyscraperImage", f)}
                    accept="image/*"
                    warning={warning.skyscraperImage}
                />
            }
        </>
    )

}

const ActionSetUp = ({ onChange }) => {

    const { products, getProducts } = useVendorProducts();
    useValueChangeListener(() => getProducts({pageNo: 1, pageSize: 1000}), [""], []);
    const productList = useMemo(() => {
        if(!products) return [];
        else return [null, ...products.map(p => ({ name: p.name, id: p.id, value: p.value }))]
    }, [products]);

    const [ config, setConfig ] = useState({});

    const updateConfig = (key, val) => {
        const updatedConfig = (key === "onClickAction") ? {[key]: val} : {...config, [key]: val};
        setConfig(updatedConfig);
        onChange(updatedConfig);
    }

    const updateProductRange = (add, product) => {
        if(!product) return;
        const updatedRange = {...config.productRange};
        if(add) {
            updatedRange[product.id] = product;
        } else {
            delete updatedRange[product.id];
        }

        updateConfig("productRange", updatedRange);
    }

    return (
        <FormItem gridColumn="span 2">
            <FormSelector
                gridRow="3"
                gridColumn="2"
                order={2}
                name="On Click Action"
                options={onClickActions}
                onSelected={a => updateConfig("onClickAction", a)}
            />
            {config.onClickAction==="Go to product page" &&
                <FormSelector
                    gridRow="4"
                    gridColumn="2"
                    order={2}
                    name="Select Product"
                    options={productList}
                    optionToString={p => p?.name}
                    onSelected={p => updateConfig("selectedProduct", p)}
                />
            }
            {config.onClickAction==="Show a range of products" &&
                <>
                    <FormItem gridRow="4" gridColumn="2" order={2}>
                        <p className="input-form-sub-heading">Add Product</p>
                        <Selector
                            options={productList}
                            optionToString={p => p?.name}
                            onSelect={p => updateConfig("tempSelectedProduct", p)}
                        />
                        <p className="clickable-text"
                           onClick={() => updateProductRange(true, config.tempSelectedProduct)}
                           style={{marginTop: "6px"}}>
                            Add
                        </p>
                    </FormItem>
                    <FormItem gridRow="5 / span 3" gridColumn="2" order={2}>
                        <div className="banner-product-range">
                            {config.productRange && Object.values(config.productRange).map(p =>
                                <p>
                                    {p.name}
                                    <span className="clickable-text" onClick={() => updateProductRange(false, p)}>
                                        remove
                                    </span>
                                </p>
                            )}
                        </div>
                    </FormItem>
                </>
            }
        </FormItem>
    )
}

const Preview = ({ bannerConfig }) => {

    const [ previewVisible, setPreviewVisible ] = useState(false);

    return (
        <Form>
            <FormHeading gridColumn="span 2">Preview</FormHeading>
            <FormItem gridColumn="span 2">
                <p className="clickable-text"
                   onClick={() => setPreviewVisible(!previewVisible)}
                >
                    {previewVisible ? "Hide Preview" : "Show Preview"}
                </p>
            </FormItem>
            {previewVisible &&
                <>
                    <FormItem className="banner-preview">
                        <BannerSVGDesktop
                            isLeaderBoard={bannerConfig.bannerType === "B" || bannerConfig.bannerType === "L"}
                            isSkyscraper={bannerConfig.bannerType === "B" || bannerConfig.bannerType === "S"}
                            leaderboard={bannerConfig.leaderboardImage?.data}
                            skyscraper={bannerConfig.skyscraperImage?.data}

                        />
                    </FormItem>
                    <FormItem className="banner-mobile-preview">
                        <BannerSVGMobile
                            leaderboard={bannerConfig.mobileLeaderboardImage?.data}
                            isLeaderBoard={bannerConfig.bannerType === "B" || bannerConfig.bannerType === "L"}
                        />
                    </FormItem>
                </>
            }
        </Form>
    )
}

const Total = ({ dates }) => {

    const daysBetween = (start, end) => Math.ceil((end - start) / (24 * 60 * 60 * 1000)) + 1

    const {
        loading,
        result : charge,
        sendIt
    } = useAuthRequest();

    useValueChangeListener(() => {
        const req = request.model("c_charge")
            .select("chargeamt")
            .filter(condition.eq("c_charge_ID", 1000205))
            .buildRequest(env.API_URL);
        const getIt = async (s) => {
            const resp = await axios.get(req, api.auth(s));
            return resp?.data?.records?.[0]?.ChargeAmt;
        }
        sendIt(getIt);
    }, [2], [])

    return (
        <Form>
            <FormHeading gridColumn="span 2">Total</FormHeading>
            <LoadingSwitcher loading={loading}>
                <FormItem>
                    {dates && dates.startDate && dates.endDate ?
                        <>
                            {charge ?
                                <p className="input-form-text" style={{fontWeight: 500, fontSize: 22}}>
                                    £{charge * daysBetween(dates.startDate, dates.endDate)}.00
                                </p> :
                                <p className="input-form-text">
                                    Something has gone wrong, we have not been able to calculate your total.
                                </p>
                            }
                        </> :
                        <p className="input-form-text">
                            Please select a date range.
                        </p>
                    }
                </FormItem>
            </LoadingSwitcher>
        </Form>
    )
}

// TODO - ADD VENDOR PRODUCT CODE TO PRODUCT NAME

const BannerForm = () => {

    const configRef = useRef({});

    const [ bannerConfig, setBannerConfig ] = useState({});
    const [ warning, setWarning ] = useState();
    const [ categoryID, setCategoryID ] = useState();
    const [ dates, setDates ] = useState();

    const { loading, result, sendIt } = useAuthRequest();

    const onCategoryIDChange = (id) => {
        configRef.current.categoryID = id;
        setCategoryID(id);
    }

    const submit = () => {
        const validation = BannerValidation.isValid(configRef.current);
        if(!validation.valid) {
            setWarning(validation.msg);
        } else {
            const ref = configRef.current;

            const req = bannerFormRequest()
                .setProductCategoryID(ref.categoryID)
                .setStartDate(idempiereDate.getPostString(ref.dateRange.startDate))
                .setEndDate(idempiereDate.getPostString(ref.dateRange.endDate))
                .setBannerClickAction(ref.action.onClickAction)
                .setProduct(ref.action.selectedProduct)
                .setProductList(ref.action.productRange)
                .setDescription(ref.comments)
                .setUpsellType("B");

            if(ref.banner.bannerType === "B" || ref.banner.bannerType === "L") {
                const lb  = ref.banner.leaderboardImage;
                const mlb = ref.banner.mobileLeaderboardImage;
                req.addAttachment({
                    name: "leaderboardImage." + lb.extension,
                    data: lb.data
                });
                req.addAttachment({
                    name: "mobileLeaderboardImage." + mlb.extension,
                    data: mlb.data
                });
            }

            if(ref.banner.bannerType === "B" || ref.banner.bannerType === "S") {
                const ss = ref.banner.skyscraperImage;
                req.addAttachment({
                    name: "skyscraperImage." + ss.extension,
                    data: ss.data
                });
            }

            sendIt(req.sendIt);
        }
    }

    if(result) {
        return (
            <div style={{maxWidth: 600}}>
                <p className="acct-h1">Form Submitted</p>
                <p className="acct-h4">
                    Thank you! Your form has been submitted. A member of our team will review the form and get back to you promptly.
                </p>
                <div style={{marginBottom: "200px"}} />
            </div>
        )
    }

    return (
        <>
            {loading &&
                <Popup>
                    <DialogOld
                        title="Submitting Form"
                        message="Submitting form please wait"
                        showLoading
                    />
                </Popup>
            }
            {warning &&
                <Popup>
                    <DialogOld
                        title={"Missing Input"}
                        message={warning}
                        neutralButton={{
                            name: "OK",
                            onClick: () => setWarning(null)
                        }}
                    />
                </Popup>
            }
            <Form style={{maxWidth: 600}}>
                <FormHeading gridColumn="span 2" order={0}>Display Details</FormHeading>
                <CategorySelector onChange={onCategoryIDChange} />
                <SectionSeparator />
                <DateSelector
                    categoryID={categoryID}
                    onChange={dr => {
                        configRef.current.dateRange = dr;
                        setDates(dr);
                    }}

                />
                <SectionSeparator />
                <ActionSetUp onChange={a => configRef.current.action = a} />
                <SectionSeparator />
                <BannerImageSetUp onChange={bc => {
                        configRef.current.banner = bc;
                        setBannerConfig(bc);
                    }}
                />
                <SectionSeparator />
            </Form>
            <Total dates={dates} />
            <SectionSeparator />
            <Preview bannerConfig={bannerConfig} />
            <SectionSeparator />
            <Form>
                <FormHeading gridColumn="span 2">Submit</FormHeading>
                <FormTextArea name="Notes/ Comments" onValueChange={(c) => configRef.current.comments = c} />
                <FormButton style={{marginTop: 20}} gridColumn="1" onClick={submit}>
                    Submit For Review
                </FormButton>
            </Form>
            <div style={{height: 100}} />
        </>
    )

}

export default BannerForm;

import {Button} from "../../../../components/general/input/Button";

export const DIALOG_BUTTON_TYPE = {
    neutral: 1,
    negative: 2,
    positive: 3,
}

const getClassName = (type) => {
    switch (type) {
        case DIALOG_BUTTON_TYPE.neutral:
            return "dialog-neutral";
        case DIALOG_BUTTON_TYPE.negative:
            return "dialog-negative";
        case DIALOG_BUTTON_TYPE.positive:
            return "dialog-positive";
        default:
            return "dialog-positive";
    }
}

const DialogButton = ({ children, type=3, onClick }) => {

    const className = getClassName(type);
    return (
        <div className={"dialog-button-wrapper " + className}>
            <Button className={className} onClick={onClick}>
                <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    {children}
                </div>
            </Button>
        </div>
    )
}

export default DialogButton;

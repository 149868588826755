import Dialog from "./Dialog";
import Popup from "../../../../components/general/popup/Popup";
import DialogTitle from "./DialogTitle";
import DialogMessage from "./DialogMessage";
import DialogButtonContainer from "./DialogButtonContainer";
import DialogButton, {DIALOG_BUTTON_TYPE} from "./DialogButton";

const GeneralMessageDialog = ({ title, message, buttonText, onDismiss }) => {

    return (
        <Popup>
            <Dialog>
                <DialogTitle>{title}</DialogTitle>
                <DialogMessage>{message}</DialogMessage>
                <DialogButtonContainer>
                    <DialogButton
                        onClick={onDismiss}
                        type={DIALOG_BUTTON_TYPE.neutral}
                    >
                        {buttonText}
                    </DialogButton>
                </DialogButtonContainer>
            </Dialog>
        </Popup>
    )
}

export default GeneralMessageDialog;

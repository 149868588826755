
const DocumentIcon = () => {

    return (
        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="39" cy="39" r="39" fill="#F0F0F0"/>
            <circle cx="38.5" cy="38.5" r="24.5" fill="#EEE93B" stroke="#ECCB42" strokeWidth="2"/>
            <path
                d="M30.9978 22H41.7333C42.7332 22 45.6111 23.2623 48.5 26C51.3889 28.7377 53 32.4 53 33.2667V50.9347C53 53.1438 51.2091 54.9333 49 54.9333H31C28.7909 54.9333 27 53.1425 27 50.9333V26C27 23.7909 28.7887 22 30.9978 22Z"
                fill="white"/>
            <path
                d="M41.7333 22C38.4492 22 34.1142 22 30.9978 22C28.7887 22 27 23.7909 27 26V50.9333C27 53.1425 28.7909 54.9333 31 54.9333H49C51.2091 54.9333 53 53.1438 53 50.9347C53 46.3637 53 38.8006 53 33.2667M41.7333 22C42.7332 22 45.6111 23.2623 48.5 26C51.3889 28.7377 53 32.4 53 33.2667M41.7333 22C43.4667 23 42.7847 30.4936 43.4667 31.2444C44.0444 31.8806 50.5 31.5 53 33.2667"
                stroke="black" strokeWidth="2"/>
            <path d="M33 39H47.1556" stroke="black" strokeWidth="2"/>
            <path d="M33 44H47.1556" stroke="black" strokeWidth="2"/>
            <path d="M33 49H47.1556" stroke="black" strokeWidth="2"/>
        </svg>
    )
}

export default DocumentIcon;

import {useSelector} from "react-redux";

const useSession = () => {

    const { session } = useSelector(
        state => state.local.auth
    )

    const getIsSignedIn = () => {
        return Boolean(session?.bPartnerID);
    }

    const getUserName = () => {
        return session?.userName;
    }

    const getBusinessName = () => {
        return session?.businessName;
    }

    const isAuthenticated = () => {
        return Boolean(session);
    }

    const getAvailableBusiness = () => {
        return session.accessibleBPs;
    }

    const getBusinessPartner = () => {
        if(!getIsSignedIn()) return;

        for(const bp of session.accessibleBPs) {
            if(bp.id === session.bPartnerID) {
                return bp;
            }
        }
    }

    const isSignedIn = getIsSignedIn();
    const businessPartner = getBusinessPartner();
    const isVendor = businessPartner?.IsVendor;
    const isCustomer = businessPartner?.IsCustomer;

    return {
        sessionData: session,
        isSignedIn,
        getIsSignedIn,
        isAuthenticated,
        getUserName,
        getAvailableBusiness,
        getBusinessName,
        isVendor,
        isCustomer
    }

}

export default useSession;

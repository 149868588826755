import { createSlice } from '@reduxjs/toolkit'
import {
    getCart,
    addToCart,
    getOrderDetails,
    updateOrder,
    checkout,
    prepareOrder,
    amendCart
} from "../action/cartActions";
import {extendBuilder} from "../setup/reduxRequestHandler";
import {reduxLoadingHandler} from "../setup/reduxLoadingHandler";

const initialState = {
    loading: {},
    error: {},

    orderID: 0,
    order: null,
    lines : null,

    completeError: null,
}

const getLineKey = (metaData) => {
    const cartItem = metaData.arg.cartItem;
    return "line_" + cartItem.product.id + "_" + cartItem.asiID;
}

const buildExtraReducers = (builder) => {

    builder
        .addCase(addToCart.pending, (state, { meta }) => {
            reduxLoadingHandler.pending(state, "line");
            reduxLoadingHandler.pending(state, getLineKey(meta));

        })
        .addCase(addToCart.fulfilled, (state, { payload, meta }) => {
            reduxLoadingHandler.fulfilled(state, "line");
            reduxLoadingHandler.fulfilled(state, getLineKey(meta));

            state.charges = payload.charges;

            if(payload.newOrder) {
                state.orderID = payload.newOrder.orderID
                state.order = payload.newOrder.order
                state.lines = payload.newOrder.items
            }

            if(payload.deleted) {
                const olID = payload.olID;
                const newLines = {...state.lines}
                delete newLines[olID];
                state.lines = newLines;
                //delete state.lines[olID];
            } else {
                if(!state.lines) state.items = {};

                const ol = payload.ol;
                state.lines[ol.id] = ol;
            }
        })
        .addCase(addToCart.rejected, (state, { payload, meta }) => {
            reduxLoadingHandler.rejected(state, "line", payload);
            reduxLoadingHandler.rejected(state, getLineKey(meta), payload);
        })

    extendBuilder(builder)
        .addStandardCase(getCart, "cart", (state, { payload }) => {
            state.orderID = payload.orderID;
            state.order = payload.order;
            state.lines = payload.items;
            state.charges = payload.charges;
        })
        .addStandardCase(getOrderDetails, "order", (state, {payload}) => {
            state.order = payload;
        })
        .addStandardCase(updateOrder, "order", (state, {payload}) => {
            state.charges = payload.charges;
            state.order = payload.data;
        })
        .addStandardCase(checkout, "checkout", (state, { payload }) => {
            if(payload.complete) {
                state.order = payload.order;
            } else {
                state.completeError = payload.message;
            }
        })
        .addStandardCase(prepareOrder, "prepare", (state, {payload}) => {

        })
        .addStandardCase(amendCart, "amend", (state, {payload}) => {
            if(payload.orderID) {
                state.orderID = payload.orderID;
            }
        })
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        clearComplete: (state) => {
            state.completeError = null;
        },
        clearCart: (state) => {
            state.orderID = 0;
            state.order = null;
            state.lines = null;
        }
    },
    extraReducers: buildExtraReducers
})

export const { clearComplete, clearCart } = cartSlice.actions;
export default cartSlice.reducer;

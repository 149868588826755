import {modelBuilder} from "./requestModelBuilder";

const API_PREFIX    = "/api/v1/";
const API_MODEL     = "models/";
const API_PROCESS   = "processes/";

// const API_SELECT = "$select=";
// const API_EXPAND = "$expand=";
// const API_FILTER = "$filter=";
// const API_TOP    = "$top=";
// const API_SKIP   = "$skip=";

const modelRequestBuilder = (prefix, modelName, isSubQuery = false, isList = false) => {

    const requestParams = {
        model: modelName,
        toSelect: [],
        toExpand: [],
        toFilter: [],
    };

    const builder = {
        requestParams
    };

    builder.hostURL = (hostURL) => {
        requestParams.hostURL = hostURL;
        return builder;
    }

    builder.modelName = (modelName) => {
        requestParams.model = modelName;
        return builder;
    }

    builder.id = (id) => {
        requestParams.id = id;
        return builder;
    }

    if(isSubQuery) {
        builder.expansionColumn = (column) => {
            requestParams.expansionColumn = column;
            return builder;
        }
    }

    builder.select = (...select) => {
        requestParams.toSelect.push(...select)
        return builder;
    }

    builder.expand = (...expand) => {
        requestParams.toExpand.push(...expand)
        return builder;
    }

    builder.filter = (...filter) => {
        requestParams.toFilter.push(...filter)
        return builder;
    }

    builder.top = (topResults) => {
        requestParams.top = topResults;
        return builder;
    }

    builder.skip = (toSkip) => {
        requestParams.skip = toSkip;
        return builder;
    }

    builder.orderBy = (toOrderBy) => {
        requestParams.orderBy = toOrderBy;
        return builder;
    }

    builder.attachment = (isAttachment=true) => {
        requestParams.isAttachment = isAttachment;
        return builder;
    }

    builder.zip = (zip=true) => {
        requestParams.zip = zip;
        return builder;
    }

    builder.fileName = (fileName) => {
        requestParams.fileName = fileName;
        return builder;
    }

    builder.buildRequest = (hostUrl) => {
        if(hostUrl) builder.hostURL(hostUrl);
        const query = modelBuilder.buildModel(requestParams, isSubQuery, isList);

        const url = (requestParams.hostURL ? requestParams.hostURL : "")
            + (prefix ? prefix : "")

        return url + query;
    }

    return builder;

}

const processRequestBuilder = (processName) => {

    const builder = {};
    const params = {};
    const config = {};

    builder.param = (name, value) => {
        params[name] = value;
        return builder;
    }

    builder.paramObject = (name, object) => {
        return builder.param(name, JSON.stringify(object));
    }

    builder.hostURL = (hostUrl) => {
        config.hostURL = hostUrl;
    }

    builder.buildRequest = (hostUrl) => {
        if(hostUrl) builder.hostURL(hostUrl);
        return (config.hostURL ? config.hostURL : "") + API_PREFIX + API_PROCESS + processName;
    }

    builder.buildRequestBody = () => {
        return params;
    }

    return builder;
}

const printRequestBuilder = (modelName, poID) => {

    // TODO add args for print format

    const builder = {};

    builder.buildRequest = (hostUrl) => {
        const host = hostUrl ? hostUrl : "";
        const api = API_PREFIX + API_MODEL;

        return `${host}${api}${modelName}/${poID}/print`
    }

    return builder;

}

export const condition = {
    eq: (x,y) => {
        return x + " eq " + y;
    },

    not: (x) => {
        return "!(" + x + ")";
    },

    neq: (x,y) => {
        return x + " neq " + y;
    },

    and: (x,y) => {
        return x + " AND " + y;
    },

    or: (x,y) => {
        return x + " OR " + y;
    },

    encapsulate: (x) => {
        return "(" + x + ")";
    },

    in: (x, ...y) => {
        const conditions = y.map(z => condition.eq(x,z));
        let inCond = null;
        conditions.forEach(cond => {
            if(inCond) inCond = condition.or(inCond, cond)
            else inCond = cond
        })
        return condition.encapsulate(inCond);
    },

    notIn: (x, ...y) => {
        const conditions = y.map(z => condition.neq(x,z));
        let inCond = null;
        conditions.forEach(cond => {
            if(inCond) inCond = condition.and(inCond, cond)
            else inCond = cond
        })
        return condition.encapsulate(inCond);
    },

    greaterThan: (x,y) => {
        return x + " gt " + y;
    },

    greaterOrEqual: (x,y) => {
        return x + " ge " + y;
    },

    lessThan: (x,y) => {
        return x + " lt " + y;
    },

    lessOrEqual: (x,y) =>  {
        return x + " le " + y;
    },

    contains: (x, y) => {
        return `contains(${x},${y})`;
    }

}

export const request = {

    model: (modelName) => modelRequestBuilder(API_PREFIX + API_MODEL, modelName, false),
    subQuery: (expandOn) => modelRequestBuilder("", expandOn, true),
    subQueryList: (expandOn) => modelRequestBuilder("", expandOn, true, true),
    process: (process) => processRequestBuilder(process),
    print: (modelName, poID) => printRequestBuilder(modelName, poID),

}


const purchaseOrderLine = (lineData) => {

    const line = {};

    line.getLineNo = () => {
        return lineData.Line;
    };

    line.getLineNetAmt = () => {
        return lineData.LineNetAmt;
    };

    line.getQuantityEntered = () => {
        return lineData.QtyEntered;
    };

    line.getUomName = () => {
        const uomSize = 1;
        const uomPrefix = uomSize > 1 ? uomSize + "x" : "";
        const caseSize = lineData.M_Product_ID.x_retailcaseqty;
        const casePrefix = caseSize > 1 ? caseSize + "x" : "";
        const unitDesc = lineData.M_Product_ID.X_ProductUnit
        const caseInfo = casePrefix + unitDesc;

        return uomPrefix + caseInfo;
        // return lineData.C_UOM_ID.Name;
    };

    line.getProductCode = () => {
        return lineData.M_Product_ID.Value;
    };

    line.getProductName = () => {
        console.log(lineData.M_Product_ID)
        return lineData.M_Product_ID.Description;
    };

    line.getImageUrl = () => {
        return lineData.M_Product_ID.ImageURL;
    };

    return line;

}

const purchaseOrder = (data) => {

    const po = {};

    po.getDocumentNo = () => {
        return data?.DocumentNo;
    };

    po.getDateOrdered = () => {
        return data?.DateOrdered;
    };

    po.getDatePromised = () => {
        return data?.DatePromised;
    };

    po.getGrandTotal = () => {
        return data.GrandTotal;
    };

    po.getLines = () => {
        return data?.c_orderline?.map(ol => purchaseOrderLine(ol)).sort((a,b) => a.getLineNo() - b.getLineNo());
    };

    po.isConfirmed = () => {
        return data?.IsConfirmed;
    }

    return po;

}

export default purchaseOrder;

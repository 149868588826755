import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import axios from "axios";

const previousPaymentsRequest = () => {

    const builder = {};

    const req = request.model("web_previous_payments_v")
        .select("total_payamt");

    builder.orderID = (orderID) => {
        req.filter(condition.eq("c_order_ID", orderID));
        return builder;
    }

    builder.sendIt = async (session) => {
        const url = req.buildRequest(env.API_URL);
        const response = await axios.get(url, api.auth(session));
        return response.data.records;
    }

    return builder;

}

export default previousPaymentsRequest;

import {MapContainer, TileLayer, Marker, useMap, } from 'react-leaflet';
import {useEffect} from "react";
import MarkerIcon from "../general/icon/MarkerIcon";
import L from 'leaflet';
import {renderToString} from "react-dom/server";

const homeBounds = [58.442,-3.093]

const ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'

const MAP_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // DEFAULT
// const MAP_URL="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" // NICE PLAIN - COMM REQ PAID

const AutoSetBounds = ({ bounds }) => {

    const map = useMap()
    useEffect(() => {
        if(bounds) {
            map.fitBounds(bounds, {padding: [50,50]})
        }
    }, [map, bounds]);

    return null;
}

const DeliveryTrackerMap = ({ delivery }) => {

    // const driverIcon = L.divIcon({
    //     html: renderToString(<TruckWithLogo style={{height: "60px", width: "auto"}} />),
    //     className: "svg-icon",
    //     iconSize: [24, 40],
    //     iconAnchor: [12, 40]
    // });

    const svgIcon = L.divIcon({
        html: renderToString(<MarkerIcon style={{height: "40px", width: "auto"}} className={"delivery-marker"} />),
        className: "svg-icon",
        iconSize: [24, 40],
        iconAnchor: [12, 40]
    });

    const driverLoc = [delivery.driver_lat, delivery.driver_long];
    const bpLoc = [delivery.bp_loc_lat, delivery.bp_loc_long];
    const mapBounds = [driverLoc, bpLoc];

    return (
        <div className="delivery-tracker-header">
            <MapContainer
                center={homeBounds}
                zoom={14}
                style={{ height: "575px", width: "100%" }}
                dragging={false}
                zoomControl={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
            >
                <TileLayer
                    url={MAP_URL}
                    attribution={ATTRIBUTION}
                />
                <Marker position={driverLoc} icon={svgIcon} />
                <Marker position={bpLoc} icon={svgIcon} />
                <AutoSetBounds bounds={mapBounds} />
            </MapContainer>
        </div>
    );
}

export default DeliveryTrackerMap;

import './calendar.css'
import {useState} from "react";
import DateUtil from "./dateUtil";

const getDefaultDisplayMonth = (startDate) => {
    if(startDate) {
        return startDate;
    } else {
        return new Date();
    }
}

const showArrow = (displayYear, displayMonth, minDate, maxDate) => {
    const [monthStart, monthEnd] = DateUtil.getMonthBounds(displayYear, displayMonth);
    const showBack = !minDate || minDate < monthStart;
    const showNext = !maxDate || maxDate > monthEnd;
    return [showBack, showNext];
}

const gridCompare = (date, displayDate, startDate, endDate, hover) => {
    const cs = startDate && DateUtil.compareDates(date, startDate);
    const ce = endDate && DateUtil.compareDates(date, endDate);

    const hnd = hover && date;
    const hns = hover && startDate;
    const hne = hover && endDate;

    const cdh = hnd && DateUtil.compareDates(date, hover);

    const csh = hns && DateUtil.compareDates(hover, startDate);
    const ceh = hne && DateUtil.compareDates(hover, endDate);

    const isMonth = displayDate.getFullYear() === date.getFullYear()
            && displayDate.getMonth() === date.getMonth();

    return {
        bs: startDate   && (cs < 0),
        is: startDate   && (cs === 0),
        as: startDate   && (cs > 0),
        be: endDate     && (ce < 0),
        ie: endDate     && (ce === 0),
        ae: endDate     && (ce > 0),

        bh:  hnd    && (cdh < 0),
        ih:  hnd    && (cdh === 0),
        ah:  hnd    && (cdh > 0),

        hbs: hns    && (csh < 0),
        his: hns    && (csh === 0),
        has: hns    && (csh > 0),

        hbe: hne    && (ceh < 0),
        hie: hne    && (ceh === 0),
        hae: hne    && (ceh > 0),


        isMonth,
    }
}

const isBlockedOut = (date, blockedOut) => {
    if(!blockedOut) return { blocked: false};

    for(const [start, end] of blockedOut) {
        const compStart = DateUtil.compareDates(date, start);
        const compEnd = DateUtil.compareDates(date, end);
        if(compStart >= 0 && compEnd <= 0) {
            return { blocked: true, start: compStart === 0, end: compEnd === 0 }
        }
    }

    return { blocked: false};
}

const isHoverInterrupted = (startDate, endDate, blockedOut, hover) => {
    if(!startDate || !blockedOut || !hover || endDate || hover < startDate) return false;

    const blocks = {};

    for(const [start, end] of blockedOut) {
        const before = DateUtil.compareDates(startDate, start);
        const after = DateUtil.compareDates(startDate, end);
        if(after > 0) blocks.after = end;
        if(before < 0) blocks.before = start;
    }

    const hoverAfter = !blocks.after || blocks.after < hover;
    const hoverBefore = !blocks.before || blocks.before > hover;

    return !(hoverAfter && hoverBefore);
}

const isInactive = (date, minDate, maxDate, inactive) => {
    if(minDate && DateUtil.compareDates(date, minDate) < 0) {
        return true;
    }
    if(maxDate && DateUtil.compareDates(date, maxDate) > 0) {
        return true;
    }
    if(inactive) {
        for(const inactiveDate of inactive) {
            if(DateUtil.compareDates(date, inactiveDate) === 0) {
                return true;
            }
        }
    }

    return false;
}

const CalendarGrid = ({ displayDates, displayDate, minDate, maxDate, startDate, endDate, onDateClick, blockedOut,
                          inactive }) => {

    const [ hover, setHover ] = useState();
    const hoverInterrupted = isHoverInterrupted(startDate, endDate, blockedOut, hover);

    const getProps = ( date ) => {
        const compare = gridCompare(date, displayDate, startDate, endDate, hover);
        const dateIsInactive = isInactive(date, minDate, maxDate, inactive);
        const blocking = isBlockedOut(date, blockedOut);
        const props = {};
        props.onClick = () => {
            if(onDateClick) onDateClick(date, {
                interrupted: hoverInterrupted,
                blocked: blocking.blocked,
                isInactive: dateIsInactive,
            });
        }
        props.onMouseEnter = () => setHover(date);
        props.onMouseLeave = () => setHover(null);

        if(blocking.blocked) {
            if(blocking.start) {
                props.className = "calendar-date-blocked-out-start";
            } else if(blocking.end) {
                props.className = "calendar-date-blocked-out-end";
            } else {
                props.className = "calendar-date-blocked-out";
            }
        } else if(dateIsInactive) {
            props.className = "calendar-date-inactive"
        } else if(compare.is) {
            props.className = (endDate || compare.has) ? "calendar-date-start" : "calendar-date-start-no-end";
        } else if (compare.ie) {
            props.className = "calendar-date-end";
        } else if (compare.as && compare.be) {
            props.className = "calendar-date-highlight";
        } else if(!endDate && compare.ih && compare.has) {
            props.className = hoverInterrupted ? "calendar-date-highlight-hover" : "calendar-date-highlight-hover-end";
        } else if(compare.ih) {
            props.className = "calendar-date-highlight-hover";
        } else if(!hoverInterrupted && compare.as && compare.bh && !compare.hae) {
            props.className = "calendar-date-highlight";
        } else if(!compare.isMonth) {
            props.className = "calendar-date-not-month";
        }


        else {
            props.className = "calendar-date-normal"
        }

        props.className = props.className + " calendar-date-container"

        return props;
    }

    return (
        <div className="calendar-body">
            {displayDates.map((date, i) =>
                <div
                    key={i}
                    {...getProps(date)}
                >
                    <p>
                        {date.getDate()}
                    </p>
                </div>
            )}
        </div>
    )
}

const Calendar = ({ minDate, maxDate, startDate, endDate, onDateClick, blockedOut }) => {

    const [displayDate, setDisplayMonth] = useState(getDefaultDisplayMonth(minDate));
    const [displayYear, displayMonth] = [displayDate.getFullYear(), displayDate.getMonth()];
    const [showBack, showNext] = showArrow(displayYear, displayMonth, minDate, maxDate);
    const displayDates = DateUtil.getCalendarDates(displayYear, displayMonth);

    const onNav = (back) => () => {
        if(back && showBack) {
            const lastMonth = new Date(displayDate);
            lastMonth.setMonth(lastMonth.getMonth() - 1);
            setDisplayMonth(lastMonth);
        } else if(!back && showNext) {
            const nextMonth = new Date(displayDate);
            nextMonth.setMonth(nextMonth.getMonth() + 1);
            setDisplayMonth(nextMonth);
        }
    }

    return (
        <div className="calendar">
            <div className="calendar-header">
                <div className="calendar-header-top">
                    <img
                        src='/assets/arrow-left.svg'
                        alt="arrow button"
                        className={!showBack ? "hide" : ""}
                        onClick={onNav(true)}
                    />
                    <p>{displayDate.toLocaleDateString('en-UK', { month: 'long' })} {displayYear}</p>
                    <img
                        src='/assets/arrow-right.svg'
                        alt="arrow button"
                        className={!showNext ? "hide" : ""}
                        onClick={onNav(false)}
                    />
                </div>
                <div className="calendar-header-bottom">
                    <p>Su</p>
                    <p>Mo</p>
                    <p>Tu</p>
                    <p>We</p>
                    <p>Th</p>
                    <p>Fr</p>
                    <p>Sa</p>
                </div>
            </div>
            <CalendarGrid
                displayDates={displayDates}
                displayDate={displayDate}
                startDate={startDate}
                endDate={endDate}
                onDateClick={onDateClick}
                blockedOut={blockedOut}
                minDate={minDate}
                maxDate={maxDate}
            />
        </div>
    )

}

export default Calendar;

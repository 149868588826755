import AccountNav from "../../../../logic/navigation/accountNav";
import usePayments from "../../../../services/state/vendor/usePayments";
import AccountPageContent from "../structure/AccountPageContent";
import Payment from "../../vendorInvoice/Payment";
import useIsAccountPage from "../structure/useIsAccountPage";
import VendorNav from "../../../../logic/navigation/vendorNav";
import AccountDocumentLayout from "../structure/AccountDocumentLayout";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.payments, {...AccountNav.items.payment, selected: true}]
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.payments, {...VendorNav.items.payment, selected: true}]

const PaymentHistoryContent = () => {

    const {
        loading,
        payments,
        count,
        getPayments
    } = usePayments();

    return (
        <AccountPageContent
            name={"payment"}
            loading={loading}
            items={payments}
            count={count}
            onFetch={getPayments}
            useLineWidth
        >
            <div>
                {payments && payments.map((p, i) =>
                    <Payment payment={p} key={i} />
                )}
            </div>
        </AccountPageContent>
    )
}

const PaymentHistoryLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    return (
        <AccountDocumentLayout breadcrumbTrail={breadcrumbTrail}>
            <PaymentHistoryContent />
        </AccountDocumentLayout>
    )
}

export default PaymentHistoryLayout;

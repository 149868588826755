import PageLayout from "./PageLayout";
import Calendar from "../../newStructure/component/general/calendar/Calendar";
import {useState} from "react";
import {Button} from "../general/input/Button";


const PageTest = () => {

    const runTest = async () => {

    };

    const [ [ startDate, endDate ], setDates ] = useState([]);
    const onSelected = (date, { interrupted, blocked, inactive }) => {
        if(blocked || inactive) return;
        if(!endDate && date > startDate && !interrupted) {
            setDates([startDate, date])
        } else {
            setDates([date]);
        }
    }

    const blockedOutDates = [[new Date('2025-01-20'), new Date('2025-01-24')]]

    // const something = () => {
    //     if(!startDate || !blockedOut || !hover || endDate) return true;
    //
    //     const blocks = {};
    //
    //     for(const [start, end] of blockedOutDates) {
    //         const before = DateUtil.compareDates(startDate, start);
    //         const after = DateUtil.compareDates(startDate, end);
    //         if(after > 0) blocks.before = end;
    //         if(before < 0) blocks.after = start;
    //     }
    //
    //     const hoverAfter = !blocks.after || blocks.after < hover;
    //     const hoverBefore = !blocks.before || blocks.before > hover;
    //
    //     return hoverAfter && hoverBefore;
    // }
    // console.log(something());



    return (
        <PageLayout>
            <div style={{minHeight: 600}}>
                <Calendar
                    minDate={new Date('2025-01-01')}
                    maxDate={new Date('2025-06-01')}
                    startDate={startDate}
                    endDate={endDate}
                    onDateClick={onSelected}
                    blockedOut={blockedOutDates}
                />

                <Button
                    style={{
                        height: "300px",
                        width: "70vw",
                        margin: "100px auto",
                        textAlign: "center",
                        display: "flex"
                    }}
                    onClick={runTest}>
                    <p style={{
                        margin: "auto auto",
                        fontSize: "10em"
                    }}>Test</p>
                </Button>
            </div>
        </PageLayout>
    )

}

export default PageTest;

import useAuthRequest from "../request/useAuthRequest";
import useValueChangeListener from "../general/useValueChangeListener";
import previousPaymentsRequest from "../../rest/request/checkout/previousPaymentsRequest";

const usePreviousPayments = ({ orderID }) => {

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const getPreviousPayments = () => {
        if(orderID > 0) {
            const req = previousPaymentsRequest().orderID(orderID);
            sendIt(req.sendIt);
        }
    }

    useValueChangeListener(getPreviousPayments, [orderID], [0]);
    const totalPaid = result?.[0]?.total_payamt
    const total = totalPaid ? totalPaid : 0;

    return {
        loading,
        error,
        total,
    }
}

export default usePreviousPayments;

import attemptLoginRefresh from "./refreshLoginController";
import {logOut} from "../slice/authSlice";
import {env} from "../../res/config/env";

const isUnauthorized = (error) => {
    return error
        && error.response
        && error.response.status === 401
}

const runAuthRequest = async (session, request, dispatch) => {
    try {
        return await request(session);
    } catch (e) {
        if(isUnauthorized(e)) {
            if(session.refreshToken) {

                const refresh_token = session?.refreshToken;
                const userId = session?.userID;
                const clientId = env.AD_CLIENT_ID;

                const authToken = await attemptLoginRefresh({refresh_token, userId, clientId}, dispatch);
                const updatedSession = {
                    ...session,
                    authToken
                }
                return await request(updatedSession);
            } else {
                dispatch(logOut());
            }
        } else {
            throw e;
        }
    }
}

export default runAuthRequest;

import AccountNav from "../../../../logic/navigation/accountNav";
import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import useShipments from "../../../../services/state/account/useShipments";
import usePrint from "../../../../../components/general/output/usePrint";
import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import LineShipment from "../../order/detailLines/LineShipment";
import LinkTextButton from "../../../navigation/link/LinkTextButton";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import React, {useEffect, useState} from "react";
import {format} from "date-fns";
import {shipmentService} from "../../../../../res/dataServices/shipmentService";
import VendorNav from "../../../../logic/navigation/vendorNav";
import useIsAccountPage from "../structure/useIsAccountPage";
import useAttachments from "../../../../services/state/account/useAttachments";
import useAttachment from "../../../../services/state/account/useAttachment";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.orders, AccountNav.items.shipment];
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.orders, VendorNav.items.shipment];

const ImageAttachment = ({ id, attachment }) => {

    const {
        loading,
        data,
        load,
    } = useAttachment("m_inout", id, attachment);

    useValueChangeListener(load, [id], []);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        if (data) {
            const url = URL.createObjectURL(data);
            console.log("created url", url)
            setImageUrl(url);
            return () => URL.revokeObjectURL(url);
        }
    }, [data]);

    return (
        <LoadingSwitcher loading={loading} skeletonStyle={{ height: "300px", width: "394px" }}>
            {imageUrl && <img src={imageUrl} alt="" />}
        </LoadingSwitcher>
    )

}

const DocumentAttachment = ({ id, attachment }) => {

    const {
        loading,
        download,
    } = useAttachment("m_inout", id, attachment);

    return (
        <LoadingSwitcher loading={loading}>
            <LinkTextButton onClick={download}>
                {attachment.name}
            </LinkTextButton>
        </LoadingSwitcher>
    )

}

const Attachments = ({ shipment }) => {

    const { loading, attachments } = useAttachments("m_inout", shipment?.id);

    const images = [];
    const other = [];
    attachments?.forEach((item) => {
        if(item.contentType === "image/jpeg") images.push(item);
        else other.push(item);
    });

    return (
        <div>
            <LoadingSwitcher loading={loading}>
                {attachments?.length === 0 &&
                    <p className="acct-h2">No attachments found</p>
                }
                {other.length > 0 &&
                    <p className="acct-h2">Documents</p>
                }
                {other.map((a,i) =>
                    <DocumentAttachment
                        key={i}
                        id={shipment?.id}
                        attachment={a} />
                )}
                {images.length > 0 &&
                    <p className="acct-h2">Images</p>
                }
                {images.length > 0 &&
                    <div className="order-images">
                        {images.map((a,i) =>
                            <ImageAttachment
                                key={i}
                                id={shipment?.id}
                                attachment={a}
                            />
                        )}
                    </div>
                }
            </LoadingSwitcher>
        </div>
    )

}

const PageHeader = ({ shipment }) => {
    const type = shipment.IsSOTrx ? "Delivery" : "Supplier Shipment";
    const documentNo = shipment.DocumentNo;
    const deliveryDate = format(shipmentService.getDeliveryDate(shipment), "dd/MM/yy")
    const locationName = shipment.C_BPartner_Location_ID.identifier;
    const statusMsg = shipmentService.getStatusMessage(shipment);

    return (
        <>
            <p className="acct-h1">Shipment {documentNo}</p>
            <p className="acct-h2">{type}</p>
            <div className="header-detail" style={{maxWidth: 600}}>
                <div>
                    <p className="acct-line-h2">Shipment No</p>
                    <p className="acct-line-h3">{documentNo}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Delivery Date</p>
                    <p className="acct-line-h3">{deliveryDate}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Location</p>
                    <p className="acct-line-h3">{locationName}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Status</p>
                    <p className="acct-line-h3">{statusMsg}</p>
                </div>
            </div>
        </>
    )
}

const ShipmentDetailContent = ({id}) => {

    const {
        loading,
        shipments,
        getShipments
    } = useShipments();

    useValueChangeListener(() => getShipments({documents: [id], includeLines: true}), [id], []);

    const shipment = shipments && shipments.length > 0 ? shipments[0] : null;
    const lines = shipment?.m_inoutline.sort((a,b) => a.Line - b.Line);

    const printUrl = request.print(api.MODEL.M_INOUT, shipment?.id).buildRequest(env.API_URL);
    const {loading: loadingPDF, fetchAndPrintIt} = usePrint({url: printUrl});

    return (
        <div className="acct-content">
            <LoadingSwitcher loading={loading}>
                {shipment && <PageHeader shipment={shipment}/>}
            </LoadingSwitcher>
            <LoadingSwitcher loading={loadingPDF}>
                <LinkTextButton
                    onClick={fetchAndPrintIt}
                    className="header-link"
                >
                    Print Shipment
                </LinkTextButton>
            </LoadingSwitcher>
            {shipment && !shipment.IsSOTrx &&
                <>
                    <p style={{marginTop: "32px", marginBottom: "12px"}} className="acct-h1">Attachments</p>
                    <Attachments shipment={shipment}/>
                </>
}
    <p style={{marginTop: "32px", marginBottom: "12px"}} className="acct-h1">Shipment Lines</p>
            <LoadingSwitcher loading={loading}>
                <div className="detail-line">
                    <p className="acct-line-h2">Line No.</p>
                    <p className="acct-line-h2" style={{gridColumn: "span 2"}}>Product</p>
                    <p className="acct-line-h2 num">Quantity</p>
                    {!loading && lines && lines.map((e, i) =>
                        <LineShipment
                            key={i}
                            shipmentLine={e}
                            highlight={i % 2 === 1}
                        />
                    )}
                </div>
            </LoadingSwitcher>
        </div>
    )
}

const ShipmentDetailLayout = ({id}) => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    const breadcrumb = [...breadcrumbTrail, {name: id, nav: breadcrumbTrail[2].nav + "/" + id, selected: true}];

    return (
        <AccountLayout breadcrumbTrail={breadcrumb}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
            <ShipmentDetailContent id={id} />
        </AccountLayout>
    )

}

export default ShipmentDetailLayout;
